body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
#root>div {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1 {
  font-size: 30px;
}

a.active {
  color: gray !important;
}

.contentWrapper {
  position: relative;
  width: 100%;
  max-width: 1024px;
  margin: auto;
  padding: 0 30px;
  padding-bottom: 50px;
}

.ant-btn-primary {
  background-color: #FC6A03 !important;
  border-color: #FC6A03 !important;

  &:disabled {
    background-color: gray !important;
    border: none;
  }
}

.footer_select {
  height: 25px;
  margin-left: 7px;
  border: none;
  outline: none;
  background-color: transparent;
}

.orderItemTitleLink {
  color: rgb(46, 132, 230) !important;
  text-decoration: none !important;
}

.orderItemTitleLink:hover {
  color: rgb(87, 166, 255) !important;
  text-decoration: none !important;
}

.orderItemTitleLink:active {
  color: rgb(119, 81, 255) !important;
  text-decoration: none !important;
}

.orderItemTitleLink:visited {
  color: rgb(119, 23, 138) !important;
  text-decoration: none !important;
}

.leftShadow {
  margin-top: 50px;
  margin-left: 0px;
  padding-left: 0px;
}

@media only screen and (min-width: 779px) {
  .contentWrapperFooter {
    position: relative;
    width: 100%;
    max-width: 1024px;
    margin: auto;
    padding: 0 30px;
    padding-bottom: 50px;
  }

  .leftShadow:before {
    content: ' ';
    position: fixed;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
    -webkit-animation: animate-shadow 1s;
    animation: animate-shadow 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes animate-shadow {
    0% {
      -webkit-transform: scaleX(0.9);
      transform: scaleX(0.9);
      opacity: 0;
      box-shadow: none;
    }

    to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
      box-shadow: 15px 0 30px 0 rgba(0, 0, 0, 0.18);
    }
  }

  @keyframes animate-shadow {
    0% {
      -webkit-transform: scaleX(0.9);
      transform: scaleX(0.9);
      opacity: 0;
      box-shadow: none;
    }

    to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
      box-shadow: 15px 0 30px 0 rgba(0, 0, 0, 0.18);
    }
  }
}


@media only screen and (max-width: 600px) {
  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}